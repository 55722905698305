import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { BrandContext } from '../../contexts/brand-context';
import { UserContext } from '../../contexts/user-context';
import { FormatPhoneInput, GetEmailRegex, ReactSelectInputNoAutoComplete, toTitleCase } from "../../services/utilities";
import { Helmet } from 'react-helmet-async';
import { useForm, Controller } from "react-hook-form";
import apiPublic from '../../services/api-public';
import apiTools from '../../services/api-tools';
import SpinnerLoader from '../layout/spinner-loader';
import { FilePath, FormatMoney } from '../../services/utilities';
import { DateTime } from 'luxon';
import CreditCardPayment from "../payments/credit-card-payment";
import Select from 'react-select';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import { Dropzone } from "dropzone";
import "dropzone/dist/dropzone.css";

const HomePortfolioForm = () => {
    const { selectedBrand } = useContext(BrandContext);
    const { pcUser } = useContext(UserContext);
    const headingRef = useRef(null);
    const { register, handleSubmit, control, setValue, getValues, watch, trigger, formState: { errors, isValid, isDirty } } = useForm({ mode: 'onChange', reValidateMode: 'onChange' });
    const formats = [{ description: "Digital Only", cost: 6 }, { description: "Printed and Digital", cost: 12 }];
    const [emailSent, setEmailSent] = useState(false);
    const [submissionError, setSubmissionError] = useState(false);
    const [csEmailAddress, setCsEmailAddress] = useState(null);
    const [dateInputMin, setDateInputMin] = useState(null);
    const [timeInputMin, setTimeInputMin] = useState(null);
    const [pickUpOfficeOptions, setPickUpOfficeOptions] = useState([]);
    const [transactionResponse, setTransactionResponse] = useState(null);
    const [transactionErrors, setTransactionErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [streetDirections, setStreetDirections] = useState([]);
    const [loadingCounties, setLoadingCounties] = useState(false);
    const [countySelectOptions, setCountySelectOptions] = useState([]);
    const [loadingSearchResults, setLoadingSearchResults] = useState(false);
    const [noResults, setNoResults] = useState(false);
    const [moreResults, setMoreResults] = useState(false);
    const [propertyInfo, setPropertyInfo] = useState(null);
    const [searchCounty, setSearchCounty] = useState(null);
    const [digitalOnlyAtNoCharge, setDigitalOnlyAtNoCharge] = useState(false);
    const [searchText, setSearchText] = useState(null);
    const [selectedCountyInfo, setSelectedCountyInfo] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [loadingPropertyResults, setLoadingPropertyResults] = useState(false);
    const [countiesFromZip, setCountiesFromZip] = useState([]);
    const [validZipCode, setValidZipCode] = useState(true);
    const [teamOrderAtNoCharge, setTeamOrderAtNoCharge] = useState(false);
    const [dropzone, setDropzone] = useState(null);
    const [folderName, setFolderName] = useState(null);

    const watchPropertyAddressStreetNumber = watch("propertyAddressStreetNumber");
    const watchPropertyAddressDirection = watch("propertyAddressDirection");
    const watchPropertyAddressStreetName = watch("propertyAddressStreetName");
    const watchPropertyAddressUnit = watch("propertyAddressUnit");
    const watchPropertyAddressCity = watch("propertyAddressCity");
    const watchFormat = watch("format");
    const watchDeliverMethod = watch("deliverMethod");
    const watchPaymentAmount = watch("paymentAmount");

    useEffect(() => {
        var searchTextTemplate = "";
        if (watchPropertyAddressStreetNumber !== "") searchTextTemplate += watchPropertyAddressStreetNumber + ' ';
        if (watchPropertyAddressDirection !== "") searchTextTemplate += watchPropertyAddressDirection + ' ';
        if (watchPropertyAddressStreetName !== "") searchTextTemplate += watchPropertyAddressStreetName + ' ';
        if (watchPropertyAddressUnit !== "") searchTextTemplate += watchPropertyAddressUnit + ' ';
        setSearchText(searchTextTemplate);
    }, [watchPropertyAddressStreetNumber, watchPropertyAddressDirection, watchPropertyAddressStreetName, watchPropertyAddressUnit]);

    useEffect(() => {
        if (pcUser) {
            setValue('name', pcUser.firstName + ' ' + pcUser.lastName)
            setValue('company', pcUser.businessName)
            setValue('customerPhone', pcUser.mobilePhone)
            setValue('email', pcUser.email)
            if (pcUser.employee) {
                setTeamOrderAtNoCharge(true);
                setValue("paymentAmount", 0);
            } else {
                setTeamOrderAtNoCharge(false);
            }
        }
    }, [pcUser]);
   
    useEffect(() => {
        if (watchFormat) {
            if (teamOrderAtNoCharge) {
                setValue("paymentAmount", 0);
            } else {
                setValue("paymentAmount", formats.find(f => f.description === watchFormat).cost);
            }

            if (watchFormat === "Digital Only") {
                setValue("deliverMethod", "email");
            } else if (watchFormat === "Printed and Digital") {
                setValue("deliverMethod", "handdeliver");
            }
        }
    }, [watchFormat]);
 
    useEffect(() => {
        const GetToolCounties = async () => {
            setLoadingCounties(true);
            apiTools.get(`/geo-counties/Home Portfolio`)
                .then((response) => {
                    var cccalcCounties = response.data;
                    var stateCountyOptions = [];
                    cccalcCounties.forEach((s) => {
                        var counties = [];
                        s.counties.forEach((c) => {
                            counties.push({ value: c.regionStateCountyId, label: c.county });
                        });
                        stateCountyOptions.push({ label: s.stateFullName, options: counties });
                    });

                    setCountySelectOptions(stateCountyOptions);
                    setLoadingCounties(false);
                });
        }

        const getStreetDirections = async () => {
            await apiPublic.get(`/title-and-escrow-order/streetdirections`)
                .then((response) => {
                    var dirs = response.data;
                    if (dirs) {
                        dirs.unshift("");
                        setStreetDirections(dirs);
                    }
                });
        }

        const getPickUpOfficeList = async () => {
            apiPublic.get(`/offices`)
                .then((response) => {
                    var officeOptions = [];
                    response.data.forEach((state) => {
                        var names = [];
                        state.forEach((office) => {
                            names.push({ value: office.officeName, label: office.officeName });
                        });
                        officeOptions.push({ label: state[0].stateFullName, options: names });
                    });
                    setPickUpOfficeOptions(officeOptions);
                });
        }
        setValue("format", "Printed and Digital");
        getStreetDirections();
        GetToolCounties();
        checkDayOfWeek();
        getPickUpOfficeList();

        if (!dropzone) setDropzone(new Dropzone("div#attachment", { url: process.env.REACT_APP_API_PUBLIC_BASE_URL + "/resware-file/attachments", createImageThumbnails: true, maxFilesize: 15000000, headers: {'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_PUBLIC_SUBSCRIPTION_KEY} }));
        setFolderName((Math.random() + 1).toString(36).substring(2));
    }, []);

    useEffect(() => {
        if (folderName && folderName !== '') { 
            dropzone.options.url = process.env.REACT_APP_API_PUBLIC_BASE_URL + "/resware-file/attachments?folderName=" + folderName;
        }
    }, [folderName]);

    function checkDayOfWeek() {
        //two days are needed to prepare order
        var defaultDate = DateTime.now().plus({ days: 2 });
        var newDayOfWeek = DateTime.now().plus({ days: 2 }).toFormat("cccc");
        var defaultTime = "08:30";

        if (newDayOfWeek === "Saturday" || newDayOfWeek === "Sunday") {
            defaultDate = defaultDate.plus({ days: 2 });
        } else if (newDayOfWeek === "Monday") {
            defaultDate = defaultDate.plus({ days: 1 });
        }
        setDateInputMin(defaultDate.toFormat("yyyy-MM-dd"));
        setTimeInputMin(defaultTime);
        setValue('neededByDate', defaultDate.toFormat("yyyy-MM-dd"));
        setValue('neededByTime', defaultTime);
    }

    const validateNeedByDateTime = () => {
        var needByDateTime = DateTime.fromFormat(getValues("neededByDate") + " " + getValues("neededByTime"), "yyyy-MM-dd HH:mm");
        var minNeedByDateTime = DateTime.fromFormat(dateInputMin + " " + timeInputMin, "yyyy-MM-dd HH:mm");
        return needByDateTime >= minNeedByDateTime;
    }
    
    const onSubmit = data => {
        setLoading(true);
        var pickupInfo = (data.deliverMethod === "pickup") ? ` \r\nPick Up Office: ${(data.pickupLocation) ? data.pickupLocation.label : ""}` : "";

        const emailOrderObject = {
            name: data.name,
            company: data.company,
            customerAddress: data.customerAddress,
            customerZip: data.customerZip,
            customerCity: data.customerCity,
            customerState: data.customerState,
            customerPhone: data.customerPhone,
            customerEmail: data.email,
            propertyAddressStreetNumber: data.propertyAddressStreetNumber,
            propertyAddressStreetName: data.propertyAddressStreetName,
            propertyAddressDirection: data.propertyAddressDirection,
            propertyAddressUnit: data.propertyAddressUnit,
            propertyAddressZip: data.propertyAddressZip,
            propertyAddressCity: data.propertyAddressCity,
            propertyAddressState: data.propertyAddressState,
            propertyAddressCounty: toTitleCase(searchCounty),
            legalDesc: data.legalDesc,
            propertyOwnerFirstName: data.ownerFirstName,
            propertyOwnerLastName: data.ownerLastName,
            propertyType: data.propertyType,
            product: "Home Portfolio",
            neededByDate: data.neededByDate,
            neededByTime: data.neededByTime,
            subdivision: data.subdivision,
            format: data.format,
            paymentTransactionId: (transactionResponse) ? transactionResponse.transId : null,
            paymentAmount: data.paymentAmount,
            deliverMethod: data.deliverMethod,
            notes: data.notes + ` ${pickupInfo}`,
            fileFolderName: folderName,
            fileUpload: dropzone.getAcceptedFiles().map(f => f.name).join(', '),
            brand: selectedBrand,
            employee: teamOrderAtNoCharge
        }

        const sendEmail = async () => {
            apiPublic.post(`/web-order`, emailOrderObject, { noErrorHandling: true })
                .then((response) => {
                    setLoading(false);
                    setEmailSent(true);
                    
                })
                .catch((err) => {
                    setLoading(false);
                    setSubmissionError(true);
                });
        };
        sendEmail();

    }

    useEffect(() => {
        if (transactionResponse && transactionResponse.transId !== "0" && transactionResponse.errors.length === 0) {
            setTransactionErrors(null);
            handleSubmit(onSubmit)();
        } else {
            if (transactionResponse && transactionResponse.errors.length > 0) {
                setTransactionErrors(transactionResponse.errors);
            }
            setLoading(false);
        }
    }, [transactionResponse]);

    useEffect(() => {
        var csEmailAddr = selectedBrand.regions[0].stateAndCounties[0].csEmailAddress;
        if (selectedBrand && searchCounty) {
            var regionForCounty = selectedBrand.regions.find(r => r.stateAndCounties.some(sc => sc.county === searchCounty.toUpperCase()));
            if (regionForCounty) {
                var county = regionForCounty.stateAndCounties.find(sc => sc.county === searchCounty.toUpperCase());
                setSelectedCountyInfo(county);
                if (county.state === "MT") {
                    setDigitalOnlyAtNoCharge(true);
                    setValue("format", "Digital Only");
                }
                else {
                    setDigitalOnlyAtNoCharge(false);
                    setValue("format", "Printed and Digital");
                }
                if (county) {
                    csEmailAddr = county.csEmailAddress;
                }
            }
        }
        setCsEmailAddress(csEmailAddr);
    }, [searchCounty, selectedBrand]);

    useEffect(() => {
        if (digitalOnlyAtNoCharge) setValue("paymentAmount", 0);
    }, [digitalOnlyAtNoCharge]);

    const handleCustomerPhoneInput = (e) => {
        const formattedPhoneNumber = FormatPhoneInput(e.target.value);
        setValue('customerPhone', formattedPhoneNumber, { shouldValidate: true });
    };

    const autoFillBasedOnZip = async (zipInput, cityFormField, stateFormField) => {
        setValue(zipInput.name, zipInput.value, { shouldValidate: true });
        if (zipInput.value.length === 5) {
            apiPublic.get(`/zipcodeinfo/${zipInput.value}`)
                .then((response) => {
                    if (!response.data) {
                        setValidZipCode(false);
                        return;
                    }
                    setValidZipCode(true);
                    if (zipInput.id === "propertyAddressZip") {
                        setCountiesFromZip(response.data.counties)
                    }
                    setValue(cityFormField, response.data.city, { shouldValidate: true });
                    setValue(stateFormField, response.data.stateAbbr, { shouldValidate: true });
                });
        }
    };

    function doSearch() {
        setNoResults(false);
        setMoreResults(false);
        setPropertyInfo(null);
        if (searchText === null) {
            setSearchText("");
            return;
        }

        if (searchCounty !== null && searchCounty !== "" && searchText !== null && searchText !== "") {
            setLoadingSearchResults(true);
            var searchCriteria = {
                address: searchText,
                county: selectedCountyInfo.county,
                state: selectedCountyInfo.state
            };
            getSearchResults(searchCriteria);
        }
    }
    const getSearchResults = async (searchCriteria) => {
        apiTools.get(`/property-search?state=${searchCriteria.state}&county=${searchCriteria.county}&searchType=siteAddr&value=${searchCriteria.address}`)
            .then((response) => {
                setSearchResults(response.data.records);
                if (response.data.records.length === 0) {
                    setNoResults(true);
                }
                if (response.data.totalCount > response.data.currentCount) {
                    setMoreResults(true);
                }
                setLoadingSearchResults(false);
            });
    }
    function fillPropertyInfo(property) {
        setLoadingPropertyResults(true);
        setPropertyInfo(null);
        apiTools.get(`/property-profile?state=${selectedCountyInfo.state}&county=${selectedCountyInfo.county}&parcelId=${property.parcelId}`)
            .then((response) => {
                setPropertyInfo(response.data);
                setValue('ownerFirstName', response.data.ownerName.split(', ')[1]);
                setValue('ownerLastName', response.data.ownerName.split(', ')[0]);
                setValue('propertyAddressStreetNumber', response.data.siteHouseNumber);
                setValue('propertyAddressDirection', response.data.siteDirectionPrefix);
                setValue('propertyAddressStreetName', response.data.siteStreetName + ' ' + response.data.siteStreetType);
                setValue('propertyAddressUnit', response.data.siteUnit);
                setValue('legalDesc', response.data.legalDescription, { shouldValidate: true });
                setValue('propertyAddressZip', response.data.siteZip, { shouldValidate: true });
                autoFillBasedOnZip(document.getElementById("propertyAddressZip"), "propertyAddressCity", "propertyAddressState");
                setLoadingPropertyResults(false);
            });
    }

    useEffect(() => {
        if (!searchCounty || !countiesFromZip || countiesFromZip.length === 0) return;
        setValidZipCode(countiesFromZip.length > 0 && searchCounty && countiesFromZip.some(c => c.county.toLowerCase() === searchCounty.toLowerCase()))
    }, [searchCounty, countiesFromZip])

    return (
        <>
            <Helmet>
                <title>Home Portfolio : {selectedBrand.brandName} </title>
            </Helmet>
            <h1 ref={headingRef}>Home Portfolio</h1>
            <div className="row">
                <div className="col-sm-6 col-md-4">
                    <img className="img-fluid" src={FilePath("/images/home_portfolio.webp")} alt="Home portfolio example" width="1000" height="1300" />
                </div>
                <div className="col-sm-6 col-md-8">
                    {transactionErrors.length > 0 &&
                        <div className="text-danger small">
                            There was an error processing your card, please try again or contact us to order.<br />
                            {transactionErrors.map((e, idx) => {
                                return <div key={idx}>{e.errorText}</div>
                            })}
                        </div>
                    }
                    {emailSent && <>
                        <p><strong>Thank you for your order! A team member will be contacting you soon.</strong></p>
                        <p>Enter another order:<br /><Link to="/title-and-escrow-order" className="btn btn-primary btn-sm">Title & Escrow Order</Link> <Link to="/property-profile" className="btn btn-primary btn-sm">Property Profile</Link> <Link to="/home-portfolio-form" reloadDocument className="btn btn-primary btn-sm">Home Portfolio</Link></p>
                    </>}
                    {!emailSent && loading && <><p className="fw-bold text-center">Submitting. Please wait for the confirmation.</p><SpinnerLoader /></>}
                    {!emailSent && !loading && submissionError && <><p className="text-danger fw-bold">We're sorry, there has been a problem submitting your order.  Please contact customer service at <a href={"mailto:" + csEmailAddress}>{csEmailAddress.toLowerCase()}</a>. </p></>}
                    {!loading && !emailSent &&
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <p>Our Home Portfolios come in two versions. Printed versions which include a QR code for quick digital access. Our digital version is emailed directly and also includes a convenient QR code.</p>
                            <h2>Customer Information:</h2>
                            <div className="row pb-1">
                                <label htmlFor="name" className="col-12 col-sm-4 col-md-3 text-md-end required">
                                    Your Name:
                                </label>
                                <div className="col-12 col-sm-6 col-md-8">
                                    <input autoComplete="name" name="name" type="text" id="name" className="form-control" {...register("name", { required: true })} />
                                    {errors?.name?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>
                            </div>
                            <div className="row pb-1">
                                <label htmlFor="company" className="col-12 col-sm-4 col-md-3 text-md-end required">
                                    Company:
                                </label>
                                <div className="col-12 col-sm-6 col-md-8">
                                    <input name="company" type="text" id="company" className="form-control" {...register("company", { required: true })} />
                                    {errors?.company?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>
                            </div>
                            <div className="row pb-1">
                                <label htmlFor="customerAddress" className="col-12 col-sm-4 col-md-3 text-md-end">
                                    Company Address:
                                </label>
                                <div className="col-12 col-sm-6 col-md-8 pb-2 pb-lg-0">
                                    <input autoComplete="street-address" name="customerAddress" type="text" id="customerAddress" className="form-control" {...register("customerAddress")} />
                                </div>
                            </div>
                            <div className="row pb-1">
                                <div className="offset-sm-3 col-8 col-lg-3 col-xl-2 pb-2 pb-lg-0">
                                    <input autoComplete="postal-code" name="customerZip" type="text" id="customerZip" maxLength="5" className="form-control" placeholder="Zip Code" {...register("customerZip")} onChange={(e) => { autoFillBasedOnZip(e.target, "customerCity", "customerState",) }} />
                                </div>
                                <div className="offset-sm-3 offset-lg-0 col-8 col-lg-3 pb-2 pb-lg-0">
                                    <input name="customerCity" type="text" id="customerCity" className="form-control" placeholder="City" {...register("customerCity")} />
                                </div>
                                <div className="offset-sm-3 offset-lg-0 col-8 col-lg-2">
                                    <input name="customerState" type="text" maxLength="2" id="customerState" className="form-control" placeholder="State" {...register("customerState")} />
                                </div>
                            </div>
                            <div className="row pb-1">
                                <label htmlFor="customerPhone" className="col-12 col-sm-4 col-md-3 text-md-end required">Phone Number:
                                </label>
                                <div className="col-8 col-lg-3 pb-2 pb-lg-0">
                                    <input autoComplete="tel" name="customerPhone" type="tel" id="customerPhone" className="form-control" {...register("customerPhone", { required: true, minLength: 12 })} onChange={(e) => handleCustomerPhoneInput(e)} />
                                    {errors?.customerPhone?.type === "required" && <small className="text-danger">This field is required</small>}
                                    {errors?.customerPhone?.type === "minLength" && <small className="text-danger">Enter a valid phone number</small>}
                                </div>
                            </div>
                            <div className="row pb-1">
                                <label htmlFor="email" className="col-12 col-sm-4 col-md-3 text-md-end required">Email:
                                </label>
                                <div className="col-12 col-sm-6 col-md-8">
                                    <input autoComplete="email" name="email" id="email" className="form-control" {...register("email", { required: true, pattern: GetEmailRegex() })} />
                                    {errors?.email?.type === "required" && <small className="text-danger">This field is required</small>}
                                    {errors?.email?.type === "pattern" && <small className="text-danger">Please enter a valid email address</small>}
                                </div>
                            </div>
                            <h2 className="pt-1">Property Information:</h2>
                            {loadingCounties && <SpinnerLoader />}
                            {!loadingCounties && countySelectOptions.length > 0 &&
                                <>
                                    <div className="row pb-1">
                                        <label className="col-12 col-sm-4 col-md-3 text-md-end required">Property County:
                                        </label>
                                        <div className="col-12 col-sm-6 col-md-8">
                                            <Controller control={control}
                                                name="County"
                                                id="County"
                                                rules={{ required: true }}
                                                render={({ field }) => {
                                                    const { onChange, name } = field;
                                                    return (
                                                        <Select
                                                            placeholder="Select County"
                                                            aria-label="Select County"
                                                            name={name}
                                                            classNamePrefix="no-localization"
                                                            options={countySelectOptions}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                                setSearchCounty(e.label);
                                                            }}
                                                            components={{ Input: ReactSelectInputNoAutoComplete }}
                                                        />);
                                                }}
                                            />
                                            {errors?.County?.type === "required" && <small className="text-danger">This field is required</small>}
                                        </div>
                                    </div>
                                </>
                            }
                            <div className="row pb-1">
                                <label htmlFor="propertyAddressDirection" className="col-12 col-md-3 text-md-end required">
                                    Property Address:
                                </label>
                                <div className="col-6 pb-1 col-md-2 pb-md-0">
                                    <input name="propertyAddressStreetNumber" autoComplete="chrome-off" aria-label="Street Number" placeholder="Street Number" type="text" className="form-control" maxLength="15" {...register("propertyAddressStreetNumber", { required: true })} />
                                    {errors?.propertyAddressStreetNumber?.type === "required" && <small className="text-danger">Required</small>}
                                </div>
                                <div className="col-6 col-md-2">
                                    <select name="propertyAddressDirection" id="propertyAddressDirection" className="form-select" {...register("propertyAddressDirection")}>
                                        {streetDirections.map((d, i) => {
                                            return <option key={i} value={d}>{d}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="col-6 col-md-3">
                                    <input name="propertyAddressStreetName" autoComplete="chrome-off" aria-label="Street Name" placeholder="Street Name" type="text" className="form-control" {...register("propertyAddressStreetName", { required: true })} />
                                    {errors?.propertyAddressStreetName?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>
                                <div className="col-6 col-md-2">
                                    <input name="propertyAddressUnit" aria-label="Address Unit" placeholder="Unit" type="text" autoComplete="chrome-off" className="form-control" {...register("propertyAddressUnit")} />
                                </div>
                            </div>

                            <div className="row pb-1">
                                <span className="col-0 col-md-3"></span>
                                <div className="col-12 col-md-9">
                                    <div className="row">
                                        <div className="col-12 pb-1 col-md-2 pb-md-0">
                                            <input name="propertyAddressZip" autoComplete="chrome-off" aria-label="Property Zip" type="text" id="propertyAddressZip" maxLength="5" className="form-control" placeholder="Zip Code"
                                                {...register("propertyAddressZip", { required: true })} onChange={(e) => { autoFillBasedOnZip(e.target, "propertyAddressCity", "propertyAddressState"); }} />
                                            <div className="small">Zip</div>
                                            {errors?.propertyAddressZip?.type === "required" && <small className="text-danger">Required</small>}
                                        </div>
                                        <div className={(watchPropertyAddressCity === "") ? "d-none" : "col-12 pb-1 col-md-4 pb-md-0"}>
                                            <input name="propertyAddressCity" aria-label="Property City" type="text" disabled={true} id="propertyAddressCity" className="form-control" {...register("propertyAddressCity")} />
                                            <div className="small">City</div>
                                        </div>
                                        <div className={(watchPropertyAddressCity === "") ? "d-none" : "col-6 pb-1 col-md-2 pb-md-0"}>
                                            <input name="propertyAddressState" aria-label="Property State" type="text" disabled={true} maxLength="2" id="propertyAddressState" className="form-control" {...register("propertyAddressState")} />
                                            <div className="small">State</div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">{!validZipCode && <small className="text-danger">This zip code is not recognized{searchCounty && " in " + searchCounty + " county"}.</small>}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pb-2">
                                <div className="offset-sm-3 col-8 col-lg-3 col-xl-2">
                                    <input className="btn btn-primary" type="button" value="Search" onClick={() => doSearch()} />
                                </div>
                            </div>

                            {(loadingSearchResults || loadingPropertyResults) && <SpinnerLoader />}
                            {!loadingSearchResults && noResults &&
                                <div className="row pt-2">
                                    <div className="row offset-0 offset-lg-3">
                                        <p className="fw-bold">No results found.</p>
                                        <p>
                                            Please try your search again.
                                        </p>
                                    </div>
                                </div>
                            }
                            {!loadingSearchResults && searchResults.length > 0 && !loadingPropertyResults && propertyInfo == null &&
                                <>
                                    {searchResults.length > 1 && <div className="row offset-0 offset-lg-3"><div className="col-12 fw-bold">Multiple properties were found. Please select a property:</div></div>}
                                    {searchResults.length === 1 && <div className="row offset-0 offset-lg-3"><div className="col-12 fw-bold">Please confirm this is the property you are looking for:</div></div>}
                                    <div className="row offset-0 offset-lg-3">
                                        <div className="col-5 col-lg-4 fw-bold">Address</div>
                                        <div className="col-5 col-lg-4 fw-bold">Owner</div>
                                        <div className="col-2"></div>
                                        <div className="col-0 col-lg-2"></div>
                                    </div>
                                    {orderBy(searchResults, ['siteAddress']).map((p, idx) =>
                                        <div className="row offset-0 offset-lg-3 pb-2" key={idx}>
                                            <div className="col-5 col-lg-4 lg-sm no-localization">{p.siteAddress}</div>
                                            <div className="col-5 col-lg-4 lg-sm no-localization">{p.ownerName}</div>
                                            <div className="col-2">
                                                <button className="btn btn-primary btn-sm" onClick={() => fillPropertyInfo(p)}>Select</button>
                                            </div>
                                            <div className="col-0 col-lg-2"></div>
                                        </div>)
                                    }
                                    {moreResults && <div className="row offset-0 offset-lg-3"><div className="col-12 fw-bold">More than {searchResults.length} properties were found, please enter a more precise address.</div></div>}
                                </>
                            }
                            <div className="row pb-1">
                                <label htmlFor="legalDesc" className="col-12 col-sm-4 col-md-3 text-md-end required">
                                    Legal Desc:
                                </label>
                                <div className="col-12 col-sm-6 col-md-8 no-localization">
                                    <textarea name="legalDesc" rows="3" cols="20" id="legalDesc" className="form-control" {...register("legalDesc", { required: true })} ></textarea>
                                    {errors?.legalDesc?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>
                            </div>
                            <div className="row pb-1">
                                <label htmlFor="ownerFirstName" className="col-12 col-sm-4 col-md-3 text-md-end">
                                    Owner: (if avail.)
                                </label>
                                <div className="col-6 col-sm-3 col-md-4 pb-2 pb-lg-0 no-localization">
                                    <input autoComplete="chrome-off" placeholder="First Name" name="ownerFirstName" type="text" id="ownerFirstName" className="form-control" {...register("ownerFirstName")} />
                                </div>
                                <div className="col-6 col-sm-3 col-md-4 pb-2 pb-lg-0 no-localization">
                                    <input autoComplete="chrome-off" placeholder="Last Name" name="ownerLastName" type="text" id="ownerLastName" className="form-control" {...register("ownerLastName")} />
                                </div>
                            </div>
                            <div className="row pb-1">
                                <label htmlFor="propertyType" className="col-12 col-sm-4 col-md-3 text-md-end">
                                    Type:
                                </label>
                                <div className="col-12 col-sm-6 col-md-8 pb-2 pb-lg-0">
                                    <select name="propertyType" id="propertyType" className="form-select" aria-label="Select Type" {...register("propertyType")}>
                                        <option value="Residential">Residential</option>
                                        <option value="Commercial">Commercial</option>
                                        <option value="Agricultural">Agricultural</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                            </div>
                            <h2 className="pt-1">Order Information:</h2>
                            {digitalOnlyAtNoCharge &&
                                <div className="row pb-1">
                                    <label className="col-12 col-sm-4 col-md-3 text-md-end">
                                        Format:
                                    </label>
                                    <div className="col-12 col-sm-6 col-md-8 pb-2 pb-lg-0">
                                        Digital <small>(Please reach out to <a href={"mailto:" + csEmailAddress}>Customer Service</a> for a Printed version.)</small>
                                    </div>
                                </div>
                            }
                            <div className={digitalOnlyAtNoCharge ? "d-none" : ""}>
                                <div className="row pb-1">
                                    <label className="col-12 col-sm-4 col-md-3 text-md-end">
                                        Format:
                                    </label>
                                    <div className="col-12 col-sm-6 col-md-8 pb-2 pb-lg-0">
                                        {formats.map((f, idx) => {
                                            return <label key={idx} className="me-3"><input type="radio" value={f.description} checked={watchFormat === f.description} {...register("format")} /> {f.description} ({FormatMoney(f.cost)})</label>;
                                        })}
                                    </div>
                                </div>
                                <div className="row pb-1">
                                    <label className="col-12 col-sm-4 col-md-3 text-md-end">
                                        Total:
                                    </label>
                                    <div className="col-12 col-sm-6 col-md-8 pb-2 pb-lg-0">
                                        <input {...register("paymentAmount")} type="hidden" />
                                        {teamOrderAtNoCharge ? "$0.00 (Waived for team members)" : FormatMoney(watchPaymentAmount)}
                                    </div>
                                </div>
                            </div>
                            <div className="row pb-1">
                                <label className="col-12 col-sm-4 col-md-3 text-md-end required">
                                    Needed By Date/Time:
                                </label>
                                <div className="col-12 col-sm-3 col-md-4">
                                    <input id="neededByDate" aria-label="Need by Date" className="form-control" type="date" required {...register("neededByDate", { validate: { needByDateTimeValid: () => validateNeedByDateTime() } })} />
                                </div>
                                <div className="col-12 col-sm-3 col-md-4">
                                    <input id="neededByTime" aria-label="Need by Time" className="form-control" type="time" {...register("neededByTime", { validate: { needByDateTimeValid: () => validateNeedByDateTime() } })} />
                                </div>
                                {(errors?.neededByDate?.type === "needByDateTimeValid" || errors?.neededByTime?.type === "needByDateTimeValid") && <div className="col-12 offset-sm-4 offset-md-3"><small className="text-danger">The earliest possible availability is {DateTime.fromFormat(dateInputMin + " " + timeInputMin, "yyyy-MM-dd HH:mm").toFormat("EEEE, MMMM d, t")}.</small></div>}
                            </div>
                            <div className="row pb-1">
                                <label htmlFor="deliverMethod" className="col-12 col-sm-4 col-md-3 text-md-end">
                                    Deliver Via:
                                </label>
                                <div className="col-12 col-sm-6 col-md-8 pb-2 pb-lg-0">
                                    <select name="deliverMethod" id="deliverMethod" className="form-select" disabled={watchFormat === "Digital Only"} aria-label="Select Deliver Method" {...register("deliverMethod")} >
                                        {watchFormat === "Digital Only" ? <option value="email">Email</option> :
                                            <><option value="handdeliver">Deliver to Brokerage</option>
                                                <option value="pickup">Pick Up at Our Office</option></>}
                                    </select>
                                </div>
                            </div>
                            {watchDeliverMethod === "pickup" &&
                                <div className="row pb-1">
                                    <label className="col-12 col-sm-4 col-md-3 text-md-end required">
                                        Office for Pick Up:
                                    </label>
                                    <div className="col-12 col-sm-6 col-md-8 pb-2 pb-lg-0">
                                        <Controller control={control}
                                            name="pickupLocation"
                                            id="pickupLocation"
                                            rules={{ required: true }}
                                            render={({ field }) => {
                                                const { onChange, name, ref } = field;
                                                return (
                                                    <Select
                                                        placeholder="Select Pick Up Location"
                                                        aria-label="Select Pick Up Location"
                                                        name={name}
                                                        classNamePrefix="no-localization"
                                                        inputRef={ref}
                                                        options={pickUpOfficeOptions}
                                                        onChange={(val) => { onChange(val); trigger("pickupLocation"); }}
                                                    />);
                                            }}
                                        />
                                        {errors?.pickupLocation?.type === "required" && <small className="text-danger">This field is required</small>}
                                    </div>
                                </div>
                            }
                            <div className="row pb-1">
                                <label htmlFor="notes" className="col-12 col-sm-4 col-md-3 text-md-end">
                                    Notes:
                                </label>
                                <div className="col-12 col-sm-6 col-md-8 pb-2 pb-lg-0">
                                    <textarea name="notes" rows="2" cols="20" id="notes" className="form-control" {...register("notes")}></textarea>
                                </div>
                            </div>
                            <div className="row pb-1">
                                <label htmlFor="attachment" className="col-12 col-md-3 text-md-end">
                                    Attach File(s):<br />
                                    <small>Logo, Property Photo, Headshot, Disclosures, etc.</small>
                                </label>
                                <div className="col-12 col-sm-6 col-md-8">
                                    <div id="attachment" className="dropzone dropzone-previews border"/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="offset-sm-4 offset-md-3">
                                    {parseFloat(getValues("paymentAmount")) > 0 ? <>
                                        <CreditCardPayment className={(!isValid || !isDirty || !validZipCode) ? "d-none" : ""} disabled={!isDirty || !isValid || !validZipCode} onClose={() => setLoading(true)} setTransactionResponse={setTransactionResponse} description="Home Portfolio" fee={Number(getValues("paymentAmount"))} buttonText="Submit and Pay" />
                                        {(!isValid || !isDirty || !validZipCode) && <button className="btn btn-primary" id="fakeButton" type="button" onClick={() => trigger()}>Submit and Pay</button>}
                                    </> :
                                        <button className="btn btn-primary" id="submitButton" type="submit" onClick={() => headingRef.current.scrollIntoView({ behavior: 'smooth' })}>Submit</button>
                                    }

                                    {(!validZipCode || (!isValid && !isEmpty(errors))) && <div className="text-danger">There was a problem with the form entries. Please see the messages above.</div>}
                                </div>
                            </div>
                        </form>}
                </div>
            </div>
        </>
    );
}

export default HomePortfolioForm;