import { useContext, useState, useEffect, useRef } from 'react';
import { BrandContext } from '../../contexts/brand-context';
import { UserContext } from '../../contexts/user-context';
import { useNavigate } from 'react-router-dom';
import { MdExitToApp } from 'react-icons/md';
import "./region-selector.scss";
import { bootstrap } from '../../app';
import LinkExternal from '../layout/link-external';
import apiTools from '../../services/api-tools';
import apiPreferredCustomer from '../../services/api-preferred-customer';

const RegionSelector = ({ linkText, toolName, toolDisplayName, toolRoute }) => {
    const { selectedBrand, brands } = useContext(BrandContext);
    const { pcUser, setPcUser, activeRegion, setActiveRegion } = useContext(UserContext);
    const [submittingAddition, setSubmittingAddition] = useState(0);
    const [selectedRegion, setSelectedRegion] = useState();
    const [toolCounties, setToolCounties] = useState(null);
    const [regionSelectionList, setRegionSelectionList] = useState(null);
    const [regionAdditionList, setRegionAdditionList] = useState(null);
    const regionSwitcherModalRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        const getToolCounties = async (tool) => {
            apiTools.get(`/tool-counties/${tool}`)
                .then((response) => {
                    setToolCounties(response.data);
                });
        }

        if (brands && pcUser) {
            if (toolName) {
                getToolCounties(toolName);
            }
            else {
                setupRegionLists();
            }
        }
    }, [brands, pcUser, toolName]);

    useEffect(() => {
        if (activeRegion) {
            setSelectedRegion(activeRegion);
        }
    }, [activeRegion]);

    useEffect(() => {
        if (toolCounties) setupRegionLists();
    }, [toolCounties]);

    const setupRegionLists = () => {
        var regionsUserHas = [];
        var regionsUserDoesNotHave = [];
        var countiesServed = "";
        var userRegion = {};
        var toolUnavailable = false;
        var toolCountyCompareList = toolCounties ? toolCounties.map(c => c.county.toLowerCase()) : [];
        brands.forEach(b => {
            b.regions.forEach(r => {
                countiesServed = r.stateAndCounties.map((c) => c.county.toLowerCase()).filter((c => toolCountyCompareList.includes(c))).sort().join(", ");
                userRegion = pcUser.regions.find(ur => ur.regionId === r.regionId);
                toolUnavailable = toolCounties && !toolCounties.some(tc => tc.regionId === r.regionId);
                if (userRegion) {
                    regionsUserHas.push({ regionId: r.regionId, regionName: r.regionName, brand: b, countiesServed: countiesServed, regionObject: r, toolUnavailable: toolUnavailable });
                }
                else {
                    regionsUserDoesNotHave.push({ regionId: r.regionId, regionName: r.regionName, brand: b, countiesServed: countiesServed, regionObject: r, toolUnavailable: toolUnavailable });
                }
            });
        });
        setRegionSelectionList(regionsUserHas);
        setRegionAdditionList(regionsUserDoesNotHave);
    }

    const closeWithoutSaving = () => {
        setSelectedRegion(activeRegion);
        var mdl = bootstrap.Modal.getInstance(regionSwitcherModalRef.current)
        mdl.hide();
    }

    const switchRegionsAndClose = () => {
        changeRegion(selectedRegion);
        var mdl = bootstrap.Modal.getInstance(regionSwitcherModalRef.current)
        mdl.hide();
    }

    const changeSelectedRegion = (r) => {
        setSelectedRegion(r.regionObject);
    };

    const changeRegion = (r) => {
        if (selectedBrand.brandId === r.brandId) {
            setActiveRegion(r);
            window.localStorage.setItem('pcuser.activeRegion', JSON.stringify(r));
        } else {
            navigate(`/preferred-customer/switch-brand/${r.regionId}`);
        }
    }

    const addRegion = (e, r) => {
        const submitRegionAddition = async (r) => {
            apiPreferredCustomer.post(`/add-region`, r.regionObject)
                .then((resp) => {
                    pcUser.regions.push(r.regionObject);
                    setPcUser({ ...pcUser});
                    var updatedList = regionAdditionList.filter(reg => reg.regionId !== r.regionId);
                    setRegionAdditionList(updatedList);
                    setSubmittingAddition(0);
                });

        }
        e.stopPropagation(); 
        setSubmittingAddition(r.regionId);
        submitRegionAddition(r);
    }

    return (
        <>
            {regionSelectionList &&
                <>
                    <button type="button" className="btn btn-link p-0 m-0 align-baseline region-btn-link" data-bs-toggle="modal" data-bs-target="#regionSwitcherModal">{linkText}</button>
                    <div className="modal" id="regionSwitcherModal" ref={regionSwitcherModalRef} tabIndex="-1" aria-labelledby="emailModalLabel" aria-hidden="true" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="emailModalLabel">Select the Region/Counties to View</h5>
                                <button type="button" className="btn-close" onClick={closeWithoutSaving} aria-label="Close"></button>
                                </div>
                                <div className="modal-body text-start">
                                        <div className="no-localization">
                                                {regionSelectionList.filter(r => r.brand.brandId === selectedBrand.brandId).map((r, i) => {
                                                    return <div key={i} className="form-check  py-1 border-top">
                                                        <input className="form-check-input" type="radio" name="regionRadios" id={`regionRadio${r.regionId}`} value={r.regionId} checked={selectedRegion.regionId === r.regionId} disabled={r.toolUnavailable} onChange={() => changeSelectedRegion(r)} />
                                                        <label className="form-check-label" htmlFor={`regionRadio${r.regionId}`}>
                                                            {r.regionName}
                                                            <div className="small text-capitalize">{r.countiesServed}</div>
                                                            {r.toolUnavailable && <div className="small">{toolDisplayName || toolName} is not available in this region, but there are other great tools available.</div>}
                                                        </label>
                                                    </div>
                                                })}
                                                {regionSelectionList.filter(r => r.brand.brandId !== selectedBrand.brandId).map((r, i) => {
                                                    return <div key={i} className="py-1 border-top d-flex flex-row">
                                                        <LinkExternal href={(!r.toolUnavailable) ? `${r.brand.url.split(';')[0]}/${toolRoute}` : r.brand.url.split(';')[0]} className="d-block brand-external-link"><MdExitToApp className="sm-react-icons" /></LinkExternal>
                                                        <div className="ps-2">
                                                            {r.regionName} - <LinkExternal href={(!r.toolUnavailable) ? `${r.brand.url.split(';')[0]}/${toolRoute}` : r.brand.url}>Visit {r.brand.brandName}</LinkExternal>
                                                            <div className="small text-capitalize">{r.countiesServed}</div>
                                                            {r.toolUnavailable && <div className="small">{toolDisplayName || toolName} is not available in this region, but there are other great tools available.</div>}
                                                        </div>
                                                    </div>
                                                })}
                                        </div>
                                        {(regionAdditionList.length > 0) &&
                                        <div className="accordion" id="accordionRegionCounties">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingOtherCounties">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOtherCounties" aria-expanded="false" aria-controls="collapseOtherCounties">
                                                                Other Counties Available
                                                            </button>
                                                        </h2>
                                                        <div id="collapseOtherCounties" className="accordion-collapse collapse" aria-labelledby="headingOtherCounties" data-bs-parent="#accordionRegionCounties">
                                                            <div className="accordion-body">
                                                                {regionAdditionList.map((r, i) => {
                                                                    return <div key={i} className="other-county py-1 border-top">
                                                                        <span className="form-check-label">
                                                                            {r.regionName} {(r.brand.brandId !== selectedBrand.brandId) && <small>(Provided by our sister company, <span className="no-localization">{r.brand.brandName}</span>)</small>}
                                                                            <div className="small text-capitalize no-localization">{r.countiesServed}</div>
                                                                            {r.toolUnavailable && <div className="small">{toolDisplayName || toolName} is not available in this region, but there are other great tools available.</div>}
                                                                            {!r.requested && <div className="small"><span className="float-start pt-1">Want these counties?</span>
                                                                                {(submittingAddition === r.regionId) ?
                                                                                    <div className="spinner-border spinner-border-sm ms-3 my-2" role="status"><span className="visually-hidden">Submitting...</span></div>
                                                                                    :
                                                                                    <button className="btn btn-primary btn-sm m-1 py-0 px-1" onClick={(e) => addRegion(e, r)}>Add Now</button>
                                                                                }
                                                                            </div>}
                                                                        </span>
                                                                    </div>
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                        }
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={switchRegionsAndClose}>Save and Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default RegionSelector;