import { createContext, useEffect, useState, useContext } from 'react';
import { MsalContext } from "@azure/msal-react";
import { EventMessageUtils } from "@azure/msal-browser"; 
import { tokenRequest } from '../auth-config';
import { useNavigate } from 'react-router-dom';
import apiTools from '../services/api-tools';
import isEqual from 'lodash/isEqual';
import { GetSelectedLanguageSubdirectory } from '../services/utilities';

const UserContext = createContext();

const UserProvider = ({ children }) => {
    const selectedBrand = JSON.parse(localStorage.getItem("selectedBrand"));
    const [pcUser, setPcUser] = useState(JSON.parse(localStorage.getItem("pcUser")));
    const [loadingUser, setLoadingUser] = useState(true);
    const [errorLoadingPcProfile, setErrorLoadingPcProfile] = useState(false);
    const [cancelLoginOrPasswordUpdate, setCancelLoginOrPasswordUpdate] = useState(false);
    const [activeRegion, setActiveRegion] = useState(null);
    const [doLoggingOfLogin, setDoLoggingOfLogin] = useState(false);
    const [pcUserProfile, setPcUserProfile] = useState(null);
    const { instance, accounts } = useContext(MsalContext);
    const [returnUrl, setReturnUrl] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        // get active account if there is one, and setup login callbacks to set active account after login
        if (accounts && accounts[0]) {
            instance.setActiveAccount(accounts[0]);
        }
        else {
            setLoadingUser(false);
        }

        const callbackId = instance.addEventCallback((message) => {
            const status = EventMessageUtils.getInteractionStatusFromEvent(message);

            if (message.eventType === 'msal:loginSuccess' && status === "none" && message.payload && message.payload.account) {
                setReturnUrl(message.payload.state);
                var account = message.payload.account;
                if (account) {
                    instance.setActiveAccount(account);
                    setDoLoggingOfLogin(true);
                }
            }
            else if (message.eventType === 'msal:loginFailure' && message.error && message.error.toString().includes("user has cancelled")) {
                setCancelLoginOrPasswordUpdate(true);
            }
            else if (message.eventType === 'msal:handleRedirectEnd') {
                var activeAccount = instance.getActiveAccount()
                if (activeAccount) {
                    instance.acquireTokenSilent({ ...tokenRequest, account: activeAccount })
                        .then((x) => {
                            ; // handled by msal:acquireTokenSuccess event handler
                        })
                        .catch((error) => {
                            clearLoginInformation();
                            setLoadingUser(false);
                        });
                }
                else {
                    clearLoginInformation();
                    setLoadingUser(false);
                }
            }
            else if (message.eventType === 'msal:acquireTokenSuccess') {
                localStorage.setItem("pcuser.accessToken", message.payload.accessToken);
                getUserProfileSilently();
            }
            else if (message.eventType === 'msal:acquireTokenFailure') {
                console.log("Token acquisition failure: ", message);
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        }
    }, []);

    useEffect(() => {
        if (pcUserProfile != null && !isEqual(pcUserProfile, pcUser)) {
            setPcUser({ ...pcUserProfile });
            if (pcUserProfile.preferredCustomerStatusId !== 1) {
                window.localStorage.setItem('email', pcUserProfile.email);
                window.localStorage.setItem('statusId', pcUserProfile.preferredCustomerStatusId);
                setPcUser(null);
                localStorage.removeItem("pcuser");
                localStorage.removeItem("pcuser.accessToken");
                setLoadingUser(false);
                instance.logoutRedirect({ postLogoutRedirectUri: GetSelectedLanguageSubdirectory() + "/preferred-customer/request-reactivation" }).catch(e => {
                    console.error(e);
                });            
            }
            var validRegions = pcUserProfile.regions.filter((r) => selectedBrand.regions.some((br) => br.regionId === r.regionId));
            var primaryRegionInBrand = selectedBrand.regions.some((br) => br.regionId === pcUserProfile.primaryRegion.regionId);
            if (validRegions.length === 0) {
                navigate('/preferred-customer/switch-brand');
            }
            else {
                var savedActiveRegion = JSON.parse(localStorage.getItem('pcuser.activeRegion'));
                if (activeRegion === null && savedActiveRegion === null) {
                    savedActiveRegion = (primaryRegionInBrand) ? pcUserProfile.primaryRegion : validRegions[0];
                    setActiveRegion(savedActiveRegion);
                    window.localStorage.setItem('pcuser.activeRegion', JSON.stringify(savedActiveRegion));
                }
                else if (activeRegion === null && savedActiveRegion !== null) {
                    if (selectedBrand.regions.filter((br) => br.regionId === savedActiveRegion.regionId).length === 0) {
                        setActiveRegion(pcUserProfile.primaryRegion);
                    }
                    else {
                        var upToDateRegion = selectedBrand.regions.find((br) => br.regionId === savedActiveRegion.regionId);
                        setActiveRegion(upToDateRegion);
                    }
                }
                if (doLoggingOfLogin) {
                    logActivityForSpecificRegion("Login Page", "Logged In", savedActiveRegion.regionId);
                    setDoLoggingOfLogin(false);
                }
            }
            setLoadingUser(false);
        }
    }, [pcUserProfile, activeRegion, doLoggingOfLogin])

    useEffect(() => {
        if (pcUser) {
            localStorage.setItem("pcuser", JSON.stringify(pcUser));
            if (returnUrl) {
                if (returnUrl === "/preferred-customer/log-out") {
                    navigate("/");
                }
                else {
                    navigate(returnUrl);
                }
                setReturnUrl(null);
            }
        }
    }, [pcUser, returnUrl]);

    const getUserProfileSilently = async () => {
        await apiTools.get(`/me`, { noErrorHandling: true })
            .then((response) => {
                const userData = response.data;
                setPcUserProfile(userData);
                setErrorLoadingPcProfile(false);
            })
            .catch((error) => {
                setErrorLoadingPcProfile(true);
                setLoadingUser(false);
            });
    }

    const clearLoginInformation = () => {
        localStorage.removeItem("pcuser");
        localStorage.removeItem("pcuser.accessToken");
        setPcUserProfile(null);
        instance.logoutRedirect({
            onRedirectNavigate: (url) => { return false; }
        });        
    }

    const logActivityForSpecificRegion = async (feature, misc, regionId) => {
        var obj = { feature: feature, misc: misc, date: new Date().toLocaleString(), regionId: regionId };
        await apiTools.post(`/log-preferred-customer-activity`, obj, { noErrorHandling: true })
            .catch((error) => {
                console.log("Error logging activity.", error);
            });
    }

    const logActivity = async (feature, misc) => {
        var obj = { feature: feature, misc: misc, regionId: activeRegion.regionId };
        await apiTools.post(`/log-preferred-customer-activity`, obj, { noErrorHandling: true })
            .catch((error) => {
                console.log("Error logging activity.", error);
            });
    }

    return (
        <UserContext.Provider value={{
            loadingUser, pcUser, setPcUser, errorLoadingPcProfile, cancelLoginOrPasswordUpdate, setCancelLoginOrPasswordUpdate, activeRegion, setActiveRegion, returnUrl, getUserProfileSilently, logActivity
        }}>
            {children}
        </UserContext.Provider>
    )
}

export { UserContext, UserProvider }