import React, { useState, useEffect, useContext, useRef } from 'react';
import { useForm, Controller } from "react-hook-form";
import { MdOutlineWorkOutline, MdOutlineNoteAdd, MdHelpOutline } from 'react-icons/md'
import { FilePath, EventTrack, ReactSelectInputNoAutoComplete } from '../../../services/utilities';
import { Helmet } from 'react-helmet-async';
import { BrandContext } from '../../../contexts/brand-context';
import isEmpty from 'lodash/isEmpty';
import { UserContext } from '../../../contexts/user-context';
import apiPublic from '../../../services/api-public';
import apiTools from '../../../services/api-tools';
import Select from 'react-select';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import ClosingCostCalcEndorsementOption from './endorsement-option';
import ClosingCostCalculatorResults from './results';
import { DateTime } from 'luxon';
import LinkExternal from '../../layout/link-external';
import { Link, useParams } from 'react-router-dom';
import { bootstrap } from '../../../app';

const ClosingCostCalculator = () => {
    const { selectedBrand } = useContext(BrandContext);
    const { loadingUser, pcUser, activeRegion, logActivity } = useContext(UserContext);
    const { register, handleSubmit, control, setValue, getValues, watch, reset, formState: { dirtyFields, errors, isSubmitted, submitCount } } = useForm({ defaultValues: { closingCostQuoteId: 0, loansToPayOff: 1 } });
    const headingRef = useRef(null);
    const tooltipTRIDRef = useRef(null);
    let { paramClosingCostQuoteId } = useParams();

    /* watchers */
    const watchCounty = watch("propertyCounty");
    const watchTransactionType = watch("transactionType", "Purchase");
    const watchPreviousPolicy = watch("previousPolicy", "false");

    /* state vars */
    const [submitButtonText, setSubmitButtonText] = useState("Get Quote");
    const [calcResults, setCalcResults] = useState(null);
    const [countySettings, setCountySettings] = useState(null);
    const [toolRegionStateCounties, setToolRegionStateCounties] = useState([]);
    const [countySelectOptions, setCountySelectOptions] = useState([]);
    const [savedQuoteName, setSavedQuoteName] = useState(null);
    const [savedQuoteValues, setSavedQuoteValues] = useState(null);

    useEffect(() => {
        if (tooltipTRIDRef.current) {
            var tooltip = new bootstrap.Tooltip(tooltipTRIDRef.current, {});
        }
    }, [calcResults]);

    useEffect(() => {
        const GetToolCounties = async () => {
            apiTools.get(`/geo-counties/ClosingCostCalc`)
                .then((response) => {
                    var cccalcCounties = response.data;
                    var stateCountyOptions = [];
                    var tempRSCList = [];
                    cccalcCounties.forEach((s) => {
                        var counties = [];
                        tempRSCList.push(...s.counties);
                        s.counties.forEach((c) => {
                            counties.push({ value: c.regionStateCountyId, label: c.county });
                        });
                        stateCountyOptions.push({ label: s.stateFullName, options: counties });
                    });

                    setToolRegionStateCounties(tempRSCList);
                    setCountySelectOptions(stateCountyOptions);
                    if (!loadingUser && pcUser) {
                        setValue("customerName", (pcUser.firstName + " " + pcUser.lastName));
                        setValue("customerEmail", pcUser.email);
                    }
                });
        }
        GetToolCounties();
    }, [loadingUser, pcUser, activeRegion, paramClosingCostQuoteId]);

    useEffect(() => {
        const getClosingCostQuote = async () => {
            const response = await apiTools.get(`/closing-cost-calc/saved-quote/${paramClosingCostQuoteId}`);
            var q = response.data;
            var countyDropdownVal = find(toolRegionStateCounties, (c) => { return (c.stateAbbr === q.propertyCounty.state && c.county === q.propertyCounty.county); });
            q = { ...q, propertyCounty: { value: countyDropdownVal.regionStateCountyId, label: countyDropdownVal.county } };            
            setValue("propertyCounty", q.propertyCounty, { shouldDirty: true });
            setValue("transactionType", q.transactionType, {shouldDirty: true });
            setSavedQuoteName(q.savedQuoteName);
            setSavedQuoteValues(q);
        }

        if (paramClosingCostQuoteId && toolRegionStateCounties.length > 0) getClosingCostQuote();

    }, [paramClosingCostQuoteId, toolRegionStateCounties]);

    useEffect(() => {
        const GetCountyClosingCostSettings = async (state, county, transType) => {
            apiTools.get(`/closing-cost-calc/settings/${state}/${county}/${transType}`)
                .then((response) => {
                    setCountySettings(response.data);
                });
        };
        if (toolRegionStateCounties && toolRegionStateCounties.length > 0) {
            if (watchCounty && watchTransactionType) {
                var selectedCounty = find(toolRegionStateCounties, (rsc) => { return rsc.regionStateCountyId === watchCounty.value; })
                if (!selectedCounty) return;
                GetCountyClosingCostSettings(selectedCounty.state, selectedCounty.county, watchTransactionType);
            } else {
                GetCountyClosingCostSettings("ID", "ADA", "Purchase");
            }
        }
    }, [watchCounty, watchTransactionType, toolRegionStateCounties]);

    useEffect(() => {
        if (countySettings) {
            setValue("quoteFormat", countySettings.quoteFormatDefault);
            var policyType = "ALTA Homeowners (CoverageOne)";
            switch (countySettings.ownersPolicyTypeDefault) {
                case "Standard":
                    policyType = "ALTA Standard Owners";
                    break;
                case "Extended":
                    policyType = "ALTA Extended Owners";
                    break;
                default:
                    break;
            }
            setValue("ownersPolicyType", policyType);
        }
        if (savedQuoteValues && countySettings && isEqual(savedQuoteValues.propertyCounty, watchCounty) && savedQuoteValues.transactionType === watchTransactionType) {
            setValue("closingCostQuoteId", savedQuoteValues.closingCostQuoteId);
            setValue("customerName", savedQuoteValues.customerName);
	        setValue("customerEmail", savedQuoteValues.customerEmail);
	        setValue("address", savedQuoteValues.address);
	        setValue("zip", savedQuoteValues.zip);
	        setValue("city", savedQuoteValues.city);
	        setValue("state", savedQuoteValues.state);
	        setValue("purchasePrice", savedQuoteValues.purchasePrice);
	        setValue("loanAmount", savedQuoteValues.loanAmount);
	        setValue("loansToPayOff", savedQuoteValues.loansToPayOff);
	        if (savedQuoteValues.previousPolicyDate) {
                setValue("previousPolicy", "true");
                setValue("previousPolicyDate", DateTime.fromISO(savedQuoteValues.previousPolicyDate).toFormat('yyyy-MM-dd'));
            }
            setValue("quoteFormat", savedQuoteValues.quoteFormat);
	        setValue("ownersPolicyType", savedQuoteValues.ownersPolicyType);
	        setValue("loanPolicyType", savedQuoteValues.loanPolicyType);
	        setValue("endAlta906", savedQuoteValues.endAlta906);
	        setValue("endAlta2206", savedQuoteValues.endAlta2206);
	        setValue("endAlta8106", savedQuoteValues.endAlta8106);
	        setValue("endAlta506", savedQuoteValues.endAlta506);
	        setValue("endClta1024", savedQuoteValues.endClta1024);
	        setValue("endClta1025", savedQuoteValues.endClta1025);
	        setValue("endAlta406", savedQuoteValues.endAlta406);
	        setValue("endAlta606", savedQuoteValues.endAlta606);
	        setValue("endAlta706", savedQuoteValues.endAlta706);
        }
    }, [savedQuoteValues, countySettings, watchCounty, watchTransactionType])

    useEffect(() => {
        if (watchTransactionType !== "Purchase") {
            setValue("purchasePrice", null);
        }
    }, [watchTransactionType]);

    useEffect(() => {
        setValue("savedQuoteName", savedQuoteName);
    }, [savedQuoteName]);

    const onSubmit = (data) => computeForm(data);
    const handleBackToForm = () => {
        setCalcResults(null);
    };

    const computeForm = (formVals) => {
        setSubmitButtonText("Calculating...")
        var stateAndCounty = find(toolRegionStateCounties, (rsc) => { return rsc.regionStateCountyId === formVals.propertyCounty.value; });
        stateAndCounty = { stateAbbr: stateAndCounty.state, county: stateAndCounty.county };
        formVals = { ...formVals, propertyCounty: stateAndCounty, loanPolicyType: countySettings.loanPolicyDisplayName };
        apiTools.post(`/closing-cost-calc/calculate`, formVals)
            .then((response) => {
                setCalcResults(response.data);
                setValue("closingCostQuoteId", response.data.closingCostQuoteId);
                headingRef.current.scrollIntoView({ behavior: 'smooth' });
                setSubmitButtonText("Submit")
            });
        if (pcUser) { logActivity("Closing Cost Quote", `Quote ${(submitCount === 0) ? "Created" : "Updated"}: ${formVals.transactionType}, Residential`); }
        EventTrack("Closing Cost Quote", "Quote Created/Updated", formVals.transactionType);
    };

    const autoFillBasedOnZip = async (zipValue) => {
        if (zipValue.length === 5) {
            apiPublic.get(`/zipcodeinfo/${zipValue}`)
                .then((response) => {
                    setValue('city', response.data.city);
                    setValue('state', response.data.stateAbbr);
                });
        }
    };

    const resetForm = () => {
        setCalcResults(null);
        setSavedQuoteName(null);
        reset({
            customerName: getValues("customerName"),
            customerEmail: getValues("customerEmail")
        });
    }

    return (
        <>
            <Helmet>
                <title>Lender Closing Cost Calculator: {selectedBrand.brandName}</title>
            </Helmet>
            <h1 ref={headingRef}>Lender Closing Cost Calculator</h1>
            {!calcResults && <>
                <p>
                    <strong>Real estate agents:</strong> Please refer to our <Link to="/net-sheet">net sheet calculator</Link> to estimate buyer and seller fees in the format you need.
                </p>
                <p>
                    <strong>Lenders:</strong> Our closing cost calculator provides an instant estimate for title policy fees. Working on a cash or commercial transaction? Feel free to <Link to="/locations">contact us</Link> directly for a rate quote. Not sure what coverage you need? <LinkExternal href={FilePath(`/documents/policy-guide-${selectedBrand.brandName.toLowerCase().replace(/ /g, "-")}.pdf`)}>Click here to review the different policy types</LinkExternal>. 
                </p>
                <div className="pb-2 mb-2 border-bottom clearfix">
                    {getValues("savedQuoteName") && <><span className="fw-bold">Closing Cost Quote Name</span> - {getValues("savedQuoteName")}</>}
                    <Link to="/saved-closing-cost-quotes" className="float-end"><MdOutlineWorkOutline className="sm-react-icons" /> View Saved</Link>
                {paramClosingCostQuoteId && <Link to="/closing-cost-calculator" className="pe-3 float-end"><MdOutlineNoteAdd className="sm-react-icons" /> Start New Quote</Link>}

                </div>

                <div className="form-labels-left-md">
                    <form onSubmit={handleSubmit(onSubmit)} >
                        <div className="row pt-1">
                            <div className="col-12 col-md-4 form-label fw-bold required">
                                <label htmlFor="customerName">Your Name:</label>
                            </div>
                            <div className="col-12 col-md-6">
                                <input autoComplete="name" name="customerName" type="text" id="customerName" className="form-control" {...register("customerName", { required: true })} />
                                {errors?.customerName?.type === "required" && <small className="text-danger">This field is required</small>}
                            </div>
                        </div>
                        <div className="row pt-1">
                            <div className="col-12 col-md-4 form-label fw-bold required">
                                <label htmlFor="customerEmail">Email:</label>
                            </div>
                            <div className="col-12 col-md-6">
                                <input autoComplete="email" name="customerEmail" type="email" id="customerEmail" className="form-control" {...register("customerEmail", { required: true })} />
                                {errors?.customerEmail?.type === "required" && <small className="text-danger">This field is required</small>}
                            </div>
                        </div>
                        <div className="row pt-1">
                            <div className="col-12 col-md-4 form-label fw-bold required">
                                <label>Property County:</label>
                            </div>
                            <div className="col-12 col-md-6 no-localization">
                                <Controller control={control}
                                    name="propertyCounty"
                                    id="propertyCounty"
                                    rules={{ required: true }}
                                    render={({ field }) => {
                                        const { onChange, name, ref, autoComplete } = field;
                                        return (
                                            <Select
                                                placeholder="Select County"
                                                id="reactSelectCounty"
                                                name={name}
                                                inputRef={ref}
                                                aria-label="Property County"
                                                options={countySelectOptions}
                                                value={watchCounty}
                                                onChange={(val) => { onChange(val); }}
                                                components={{ Input: ReactSelectInputNoAutoComplete } }
                                            />);
                                    }}
                                />
                                {errors?.propertyCounty?.type === "required" && <small className="text-danger">This field is required</small>}
                            </div>
                        </div>
                        <div className="row pt-1">
                            <div className="col-12 col-md-4 form-label fw-bold required">
                                <label htmlFor="transactionType">Transaction Type:</label>
                            </div>
                            <div className="col-12 col-md-6">
                                <select className="form-select" name="transactionType" id="transactionType" {...register("transactionType", { required: true })}>
                                    <option value="Purchase">Purchase</option>
                                    <option value="Refinance">Refinance</option>
                                    <option value="Construction">Construction</option>
                                </select>
                                {errors?.transactionType?.type === "required" && <small className="text-danger">This field is required</small>}
                            </div>
                        </div>
                        <div className="row pt-1">
                            <div className="col-12 col-md-4 form-label fw-bold">
                                <label>Property Type:</label>
                            </div>
                            <div className="col-12 col-md-6">Residential</div>
                        </div>
                        <div className="row pt-1">
                            <div className="col-12 col-md-4 form-label fw-bold required">
                                <label htmlFor="address">Property Address:</label>
                            </div>
                            <div className="col-12 col-md-6">
                                <input autoComplete="chrome-off" name="address" type="text" id="address" className="form-control" {...register("address", { required: true })} />
                                {errors?.address?.type === "required" && <small className="text-danger">This field is required</small>}
                            </div>
                        </div>
                        <div className="row pt-1">
                            <div className="col-12 col-md-4 form-label fw-bold required">
                                <label htmlFor="zip">Zip</label>/<label htmlFor="city">City</label>/<label htmlFor="state">State</label>:
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="row">
                                    <div className="col-12 col-md-3 pt-1 pt-md-0 pe-md-1 pe-xl-2">
                                        <input name="zip" type="text" id="zip" autoComplete="chrome-off" maxLength="5" className="form-control" placeholder="Zip" {...register("zip", { required: true })} onChange={(e) => { autoFillBasedOnZip(e.target.value); }} />
                                    </div>
                                    <div className="col-12 col-md-6 pt-1 pt-md-0 px-md-1 px-xl-2">
                                        <input name="city" type="text" id="city" autoComplete="chrome-off" className="form-control col-12 col-md-5" placeholder="City" {...register("city", { required: true })} />
                                    </div>
                                    <div className="col-12 col-md-3 pt-1 pt-md-0 ps-md-1 ps-xl-2">
                                        <input name="state" type="text" maxLength="2" id="state" autoComplete="chrome-off" className="form-control col-12 col-md-5" placeholder="State" {...register("state", { required: true })} />
                                    </div>
                                    <div className="col-12">
                                        {(errors?.zip?.type === "required" || errors?.city?.type === "required" || errors?.state?.type === "required") &&
                                            <small className="text-danger">These fields are required</small>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(watchTransactionType === "Purchase") &&
                            <div className="row pt-1">
                                <div className="col-12 col-md-4 form-label fw-bold required">
                                    <label htmlFor="purchasePrice">Purchase Price:</label>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                        <input autoComplete="chrome-off" className="form-control" id="purchasePrice" type="number" step="0.01" onWheel={(e) => e.target.blur()} {...register("purchasePrice", { required: true, min: 0.01, max: ((countySettings) ? Number(countySettings.maxPurchasePriceForOnlineRateDisclosure) : 0) })} />
                                    </div>
                                    {errors?.purchasePrice?.type === "required" && <small className="text-danger">This field is required</small>}
                                    {errors?.purchasePrice?.type === "min" && <small className="text-danger">Must be greater than zero</small>}
                                    {errors?.purchasePrice?.type === "max" && <small className="text-danger">Please give us a call for a custom quote.</small>}
                                </div>
                            </div>
                        }
                        <div className="row pt-1">
                            <div className="col-12 col-md-4 form-label fw-bold required">
                                <label htmlFor="loanAmount">Loan Amount:</label>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="input-group">
                                    <span className="input-group-text">$</span>
                                <input autoComplete="chrome-off" className="form-control" id="loanAmount" name="loanAmount" type="number" step="0.01" onWheel={(e) => e.target.blur()} {...register("loanAmount", { required: true, min: 0.01, max: ((countySettings && watchTransactionType === "Refinance") ? Number(countySettings.maxLoanAmountForOnlineRefiRateDisclosure) : 1000000000), validate: { lessThanPurchasePrice: (value) => (watchTransactionType !== "Purchase" || value <= Number(getValues("purchasePrice"))) } })} />
                                </div>
                                {errors?.loanAmount?.type === "required" && <small className="text-danger">This field is required</small>}
                                {errors?.loanAmount?.type === "lessThanPurchasePrice" && <small className="text-danger">NOTE: Loan amount is greater than the purchase price. Please call for a quote.</small>}
                                {errors?.loanAmount?.type === "min" && <small className="text-danger">Must be greater than zero</small>}
                                {errors?.loanAmount?.type === "max" && <small className="text-danger">Please give us a call for a custom quote.</small>}
                            </div>
                        </div>
                        <div className="row pt-1">
                            <div className="col-12 col-md-4 form-label fw-bold">
                                <label htmlFor="loansToPayOff" className="lh-sm"><span className="d-none d-xxl-inline">Number of l</span><span className="d-inline d-xxl-none">L</span>oans being paid off:</label>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="input-group">
                                    <select id="loansToPayOff" name="loansToPayOff" size="1" className="form-select w-100" {...register("loansToPayOff")}>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="0">None/Unknown</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className={(countySettings && watchCounty && countySettings.showQuoteFormatOption) ? "row pt-1" : "d-none"}>
                            <div className="col-12 col-md-4 form-label fw-bold">
                                Quote Format: 
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="d-flex">
                                    <div className="form-check pe-3">
                                        <input className="form-check-input" type="radio" name="quoteFormat" id="quoteFormatTRID" value="TRID" {...register("quoteFormat")} />
                                        <label className="form-check-label" htmlFor="quoteFormatTRID">TRID</label><sup><button type="button" ref={tooltipTRIDRef} className="btn btn-link p-0" data-bs-toggle="tooltip" data-bs-placement="top" title="Disclosed, non-discounted rate"><MdHelpOutline className="fs-6" /></button></sup>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="quoteFormat" id="quoteFormatActual" value="Actual" {...register("quoteFormat")} />
                                        <label className="form-check-label" htmlFor="quoteFormatActual">Actual</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {(watchTransactionType === "Purchase") &&
                            <div className="row pt-1">
                                <div className="col-12 col-md-4 form-label fw-bold">
                                    Type of Owners Policy:
                                </div>
                                <div className="col-12 col-md-6">
                                    {countySettings && <>
                                        {countySettings.showBaseTitleRateOption &&
                                            <div className="form-check">
                                            <input className="form-check-input" type="radio" name="ownersPolicyType" id="ownersPolicyAltaStandardOwners" value="ALTA Standard Owners" {...register("ownersPolicyType")} />
                                                <label className="form-check-label" htmlFor="ownersPolicyAltaStandardOwners">ALTA Standard Owners</label>
                                            </div>
                                        }
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="ownersPolicyType" id="ownersPolicyAltaExtendedOwners" value="ALTA Extended Owners" {...register("ownersPolicyType")} />
                                            <label className="form-check-label" htmlFor="ownersPolicyAltaExtendedOwners">ALTA Extended Owners</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" defaultChecked name="ownersPolicyType" id="ownersPolicyAltaHomeownersCoverageOne" value="ALTA Homeowners (CoverageOne)" {...register("ownersPolicyType")} />
                                            <label className="form-check-label" htmlFor="ownersPolicyAltaHomeownersCoverageOne">ALTA Homeowners (CoverageOne)</label>
                                        </div>
                                    </>}
                                </div>                                    
                            </div>
                        }
                        <div className="row pt-1">
                            <div className="col-12 col-md-4 form-label fw-bold">
                                Type of Loan Policy:
                            </div>
                            <div className="col-12 col-md-6">
                                {countySettings && countySettings.loanPolicyDisplayName}
                                <small className="d-block"><strong >NOTE: For additional loans, please call for a quote.</strong></small>
                            </div>
                        </div>

                        {(countySettings && watchTransactionType === "Refinance" && countySettings.showPreviousPolicyCreditOption) &&
                            <div className="row pt-1">
                                <div className="col-12 col-md-4 form-label fw-bold">
                                    <label className="lh-sm">Has there been a previous policy issued in the last five years?:</label>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" defaultChecked name="previousPolicy" id="previousPolicyNo" value="false" {...register("previousPolicy")} />
                                        <label className="form-check-label" htmlFor="previousPolicyNo">No</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="previousPolicy" id="previousPolicyYes" value="true" {...register("previousPolicy")} />
                                        <label className="form-check-label" htmlFor="previousPolicyYes">Yes</label>
                                    </div>
                                    {(watchPreviousPolicy === "true") &&
                                        <>
                                            <div className="row pt-1">
                                                <div className="col-12">
                                                    <label htmlFor="previousPolicyDate" className="lh-sm">Date of previous insurance policy:</label>
                                                    <input name="previousPolicyDate" type="date" id="previousPolicyDate" className="form-control" {...register("previousPolicyDate", { required: true, validate: { validDate: (value) => (DateTime.fromISO(value) < DateTime.now().startOf("day")) && (DateTime.fromISO(value) > DateTime.fromFormat("01/01/1800", "MM/dd/yyyy")) } })} />
                                                    <div>
                                                        {errors?.previousPolicyDate?.type === "required" && <small className="text-danger">Date is required</small>}
                                                        {errors?.previousPolicyDate?.type === "validDate" && <small className="text-danger">Date is not valid. </small>}
                                                        {errors?.previousPolicyDate?.type === "validDate" && (DateTime.fromISO(errors?.previousPolicyDate.ref.value) >= DateTime.now().startOf("day")) && <small className="text-danger">Date must be in the past.</small>}
                                                    </div>
                                                    <div className="small">Credit will be based on new loan amount.</div>
                                                </div>
                                            </div>

                                        </>
                                    }
                                </div>
                            </div>
                        }

                        <div className="row pt-1">
                            <div className="col-12 col-md-4 form-label fw-bold">
                                <label>Loan Endorsements:</label>
                            </div>
                            <div className="col-12 col-md-6" id="loanEndorsements">
                                {countySettings && <>
                                    <ClosingCostCalcEndorsementOption register={register} setValue={setValue} dirtyFields={dirtyFields} labelText="ALTA 9-06 (Restrictions)" formId="endAlta906" endorsementDefUrl="/endorsements/search/ALTA ENDORSEMENT 9-06" endorsementConfig={countySettings.alta906} key="alta906" />
                                    <ClosingCostCalcEndorsementOption register={register} setValue={setValue} dirtyFields={dirtyFields} labelText="ALTA 22-06 (Improvements)" formId="endAlta2206" endorsementDefUrl="/endorsements/search/ALTA ENDORSEMENT 22-06" endorsementConfig={countySettings.alta2206} key="alta2206" />
                                    <ClosingCostCalcEndorsementOption register={register} setValue={setValue} dirtyFields={dirtyFields} labelText="ALTA 8.1-06 (Environmental Liens)" formId="endAlta8106" endorsementDefUrl="/endorsements/search/ALTA ENDORSEMENT 8.1-06" endorsementConfig={countySettings.alta8106} key="alta8106" />
                                    <ClosingCostCalcEndorsementOption register={register} setValue={setValue} dirtyFields={dirtyFields} labelText="ALTA 5-06 (PUD)" formId="endAlta506" endorsementDefUrl="/endorsements/search/ALTA ENDORSEMENT 5-06" endorsementConfig={countySettings.alta506} key="alta506" />
                                    {watchTransactionType === "Construction" &&
                                        <>
                                            <ClosingCostCalcEndorsementOption register={register} setValue={setValue} dirtyFields={dirtyFields} labelText="CLTA 102.4 (Foundation)" formId="endClta1024" endorsementDefUrl="/endorsements/search/CLTA ENDORSEMENT FORM 102.4" endorsementConfig={countySettings.clta1024} key="clata1024" />
                                            <ClosingCostCalcEndorsementOption register={register} setValue={setValue} dirtyFields={dirtyFields} labelText="CLTA 102.5 (Foundation)" formId="endClta1025" endorsementDefUrl="/endorsements/search/CLTA ENDORSEMENT FORM 102.5" endorsementConfig={countySettings.clta1025} key="clta1025" />
                                        </>
                                    }
                                    <ClosingCostCalcEndorsementOption register={register} setValue={setValue} dirtyFields={dirtyFields} labelText="ALTA 4-06 (Condo)" formId="endAlta406" endorsementDefUrl="/endorsements/search/ALTA ENDORSEMENT 4-06" endorsementConfig={countySettings.alta406} key="alta406" />
                                    <ClosingCostCalcEndorsementOption register={register} setValue={setValue} dirtyFields={dirtyFields} labelText="ALTA 6-06 (ARM)" formId="endAlta606" endorsementDefUrl="/endorsements/search/ALTA ENDORSEMENT 6-06" endorsementConfig={countySettings.alta606} key="alta606" />
                                    <ClosingCostCalcEndorsementOption register={register} setValue={setValue} dirtyFields={dirtyFields} labelText="ALTA 7-06 (Manufactured Housing)" formId="endAlta706" endorsementDefUrl="/endorsements/search/ALTA ENDORSEMENT 7-06" endorsementConfig={countySettings.alta706} key="alta706" />
                                </>
                                }
                            </div>
                        </div>


                        <div className="row my-3">
                            <div className="col d-flex justify-content-evenly">
                                <input type="hidden" id="closingCostQuoteId" {...register("closingCostQuoteId")} />
                                <input type="hidden" id="savedQuoteName" {...register("savedQuoteName")} />
                                <input type="submit" id="calculateButton" value={submitButtonText} className="btn btn-primary" />
                            </div>
                        </div>

                    </form>
                </div>
            </>
        }
        {calcResults && isSubmitted && isEmpty(errors) &&
            <ClosingCostCalculatorResults results={calcResults} setSavedQuoteName={setSavedQuoteName} handleBackToForm={handleBackToForm} handleStartNewQuote={resetForm} pcUser={pcUser} selectedBrand={selectedBrand} logActivity={logActivity} />
        }
        </>
    );
}

export default ClosingCostCalculator;